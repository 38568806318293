import React from 'react'
import { products } from '../../mock/products/product_mock';
import { AppLink } from '../navigation/app_link';
import TwoRowSection from '../two_row_section/two_row_section';

import './product_box.css';

export default function ProductBox() {
    return (
        <div className=' mt-6'>
            <h6>Products for you</h6>
            <ProductDisplay products={products} />
        </div>
    );
}


export const ProductDisplay = ({ products }) => {
    return (
        <div className='d-grid-items'>
            {
                (products && products.length) &&
                products.map((product) => (
                    <TwoRowSection
                        key={product.id}
                        topClassName='pt-4 pl-4 pr-4 pb-0'
                        bottomClassName='pb-2'
                        top={() => (
                            <AppLink className='d-flex text-capitalize text-rob' route={`/product/${product.id}`}>
                                <ProductImageDisplay product={product} />
                            </AppLink>
                        )}
                        bottom={() => <ProductSelection isInCart={product.id % 2 === 0} quantityInCart={2}
                            onAddToCart={() => { }} onChangeQuantity={() => { }}
                            onIncreaseQuantity={() => { }} onReduceQuantity={() => { }} />} />

                )
                )
            }
        </div>
    );
}

const ProductImageDisplay = ({ product }) => {
    return (
        <div className='d-flex flex-vertical'>
            <div className='d-flex mb-2 j-end'>
                {
                    product.hasDiscount &&
                    <span className='promo-container alt-three-bg-color p-1 text-white text-center'>Promo</span>
                }

            </div>
            <img src={product.image} title={product.title} alt={product.title} className='category-image' />
            <span>{product.title}</span>
            <div>
                <span>{product.hasDiscount ? <del>₦{product.price}</del> : '₦' + product.price} </span>
                {product.hasDiscount && <span>₦{product.price} </span>}
            </div>

        </div>
    );
}

export const ProductSelection = ({ className, isInCart, quantityInCart, onIncreaseQuantity, onReduceQuantity, onChangeQuantity, onAddToCart }) => {
    return (
        <div className={`d-flex j-content-center ${className || ''}`}>
            {
                isInCart
                    ? <CartQantityHandle
                        quantityInCart={quantityInCart} onIncreaseQuantity={onIncreaseQuantity}
                        onReduceQuantity={onReduceQuantity} onChangeQuantity={onChangeQuantity} />
                    : <button type='button' className='add-to-cart pl-3 pr-3 pt-2 pb-2' onClick={onAddToCart}>Add to Cart</button>
            }
        </div>
    );
}

export const CartQantityHandle = ({ quantityInCart, onIncreaseQuantity, onReduceQuantity, onChangeQuantity }) => {
    return (
        <div className='d-flex cart-handle'>
            <button type='button' className='add-to-cart add pl-3 pr-3 pt-2 pb-2' onClick={onIncreaseQuantity}> - </button>
            <input type='text' className='quantity-in-cart' value={quantityInCart} onChange={({ target: { value } }) => onChangeQuantity(value)} />
            <button type='button' className='add-to-cart subtract pl-3 pr-3 pt-2 pb-2' onClick={onReduceQuantity}> + </button>
        </div>
    );
}