import React from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/layout';
import { Home } from './pages/home/home';
import Login from './pages/login/login';

import './custom.css'
import Register from './pages/register/register';
import { ScrollToTop } from './components/scroll_to_top';
import Categories from './pages/categories/categories';
import PromotionProducts from './pages/promotion_products/promotion_products';
import NewProducts from './pages/new_products/new_products';
import FeaturedProducts from './pages/featured_products/featured_products';
import CategoryProducts from './pages/category_products/category_products';
import ProductDetail from './pages/product_detail/product_detail';
import Checkout from './pages/checkout/checkout';

export default function App() {
    return (
        <ScrollToTop>
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/categories' component={Categories} />
                    <Route exact path='/categories/:id' component={CategoryProducts} />
                    <Route exact path='/product/:id' component={ProductDetail} />
                    <Route path='/promotions' component={PromotionProducts} />
                    <Route path='/new' component={NewProducts} />
                    <Route path='/featured' component={FeaturedProducts} />
                    <Route path='/checkout' component={Checkout} />
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/register">
                        <Register />
                    </Route>
                </Switch>
            </Layout>
        </ScrollToTop>
        
    );
}

App.displayName = App.name;