import React from 'react';
import Banner from '../../components/banner/banner';
import FeaturedCategories from '../../components/featured_category/featured_category';
import ProductBox from '../../components/product_box/product_box';

export const Home = (props) => {
    return (
        <div>
            <Banner />
            <FeaturedCategories />
            <ProductBox />
        </div>
    );
}

Home.displayName = Home.name;