import React, { useEffect, Fragment } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetAppData } from './cart/app_data_action';

export const ScrollToTop = withRouter(({ history, children }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    useEffect(() => {
        dispatch(resetAppData());
        const unlisten = history.listen(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
        return () => {
            unlisten();
        }
    }, [pathname, history]);

    return <Fragment>{children}</Fragment>;
})