export const carts = [
    {
        id: 1,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/keila-logo.svg'
    },
    {
        id: 2,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/keila-logo.svg'
    },
    {
        id: 3,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/keila-logo.svg'
    },
    {
        id: 4,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/keila-logo.svg'
    }
]