import React, { useEffect, useState } from "react";
import { searchData } from "../../mock/search/search_mock";
import CheckoutButton from "../checkout_button/checkout_button";
import { ProductSelection } from "../product_box/product_box";
import { useSelector } from 'react-redux';

import './search.css';
import { useLocation } from "react-router-dom";
import useFocus from "../../custom_hooks/use_focus";

export default function Search({ className }) {
    const { pathname } = useLocation();
    const [inputRef, setInputFocus] = useFocus();
    const [term, setTerm] = useState('');
    const [results, setResults] = useState(null)
    const [hideableArea, setHideableArea] = useState(true);
    
    useEffect(() => {
       setToDefault()
    }, [pathname]);
    
    useEffect(() => {
        if (termIsValid(term)) {
            getSearchData(term)
        } else {
            setResults(null)
        }
    }, [term])

    const setToDefault = () => {
        setTerm('')
        setResults(null)
    }

    const getSearchData = (term) => {
        setResults(searchData);
    }
    const termIsValid = (term) => term && term.trim() && term.length >= 3

    const onBlur = () => {
        if (hideableArea) {
            setToDefault();
        }
    }

    return (
        <div
            onMouseOver={() => setHideableArea(false)}
            onMouseLeave={() => setHideableArea(true)}
            className='variable-width d-inline-flex p-relative'>
            <input type='search'
                ref={inputRef}
                onFocus={() => setHideableArea(false)}
                onBlur={onBlur}
                value={term}
                className={`${className || ''} full-width search-input round-border-area`}
                onChange={({ target: { value } }) => setTerm(value)} />
            <SearchArea onClick={() => setInputFocus()} results={results} />

        </div>


    );
}

Search.displayName = 'second layer for nav'

const SearchArea = ({ results, onClick }) => {
    const { searchIsOpen } = useSelector(state => state.appData)
    return (
        (!!results && searchIsOpen)
            ? results.length
                ?
                <div onClick={onClick} className='search-result d-block p-3 mt-3 full-width p-absolute'>
                    {
                        results.map((result, index) => (
                            <SearchResult
                                key={result.id}
                                result={result}
                                className={(index < (results.length - 1)) && 'mb-2'} />))
                    }
                </div>
                : <p>No result</p>

            : <p></p>
    );

}

const SearchResult = ({ result, className }) => {
    return (
        <div className={`d-flex search-item j-content p-2 bg-white ${className || ''}`}>
            <img src={result.image} alt={result.title} className='search-image mr-2' />
            <span className='search-item-tilte mr-2'>{result.title}</span>
            <span className='mr-2'>₦{result.price}</span>
            <ProductSelection
                className='mr-2'
                isInCart={true}
                quantityInCart={1}
                onIncreaseQuantity={() => { }}
                onReduceQuantity={() => { }}
                onChangeQuantity={() => { }}
                onAddToCart={() => { }} />
            <CheckoutButton className='button-style'>Check out</CheckoutButton>
        </div>
    );
}