import React from "react";
import { ProductDisplay } from "../../components/product_box/product_box";
import { products } from "../../mock/products/product_mock";

export default function PromotionProducts() {
    //TODO: write logic to get promotion products
    return (
        <div className=' mt-6'>
            <h6>Promotional Products</h6>
            <ProductDisplay products={products} />
        </div>
    );
}


