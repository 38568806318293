import { CLOSE_CART, OPEN_CART, OPEN_SEARCH_AREA, REMOVE_SEARCH_AREA, RESET_APP_DATA } from "./app_data_action";

const initialAppData = {
    cartIsOpen: false,
    searchIsOpen: true,
}

export default function appDataReducer(state = initialAppData, action) {
    switch (action.type) {
        case OPEN_CART:
            return { ...state, cartIsOpen: true }
        case CLOSE_CART:
            return { ...state, cartIsOpen: false }
        case REMOVE_SEARCH_AREA:
            return { ...state, searchIsOpen: false }
        case OPEN_SEARCH_AREA:
            return { ...state, searchIsOpen: true }
        case RESET_APP_DATA:
            return { ...state, ...initialAppData }
        default:
            return state;
    }
}