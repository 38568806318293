import React, { useState } from 'react';
import './checkout.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Input, { Button } from '../../components/input/input';

export default function Checkout() {
    return (
        <div className='d-flex mt-4'>
            <div className='width-70 mr-4'>
                <Address isValid={false} shouldOpen={true} />
                <DeliveryMethod isValid={false} shouldOpen={true} />
                <PaymentMethod isValid={false} shouldOpen={true} />
            </div>
            <div className='bg-white width-30 p-3 flex-start'>
                <p>Summary order</p>
            </div>
        </div>
    );
}

const Address = ({ isValid, shouldOpen }) => {
    return (
        <Wrapper className='mb-3' index={1} name='Address' isValid={isValid} shouldOpen={shouldOpen}>
            <form className='mt-4'>
                <div className="register-flex">
                    <div className="half-input mr-1">
                        <Input placeholder="First name" />
                    </div>
                    <div className="half-input ml-1">
                        <Input placeholder="Last name" />
                    </div>
                </div>
                <div className="register-flex">
                    <div className="quarter-input mr-1">
                        <Input disabled={true} value="+234" />
                    </div>
                    <div className="three-quarter-input ml-1">
                        <Input placeholder="Phone number" />
                    </div>
                </div>
                <div>
                    <textarea placeholder='Address' className='input height-100'>
                    </textarea>
                </div>
                <Button className='text-uppercase mt-3' type='button' value="save and continue" />
            </form>
        </Wrapper>
    );
}

const DeliveryMethod = ({ isValid, shouldOpen }) => {
    return (
        <Wrapper className='mb-3' index={2} name='Delivery Method' isValid={isValid} shouldOpen={shouldOpen}>
            <div className='mt-4'>
                <p className='text-bold'>How do you want your delivery?</p>
                <form>
                    <ul className='delivery-methods pl-0'>
                        <li><label className='p-cursor'><input type='radio' name='deliveryMethod' /> <span>Home Delivery</span></label></li>
                        <li><label className='p-cursor'><input type='radio' name='deliveryMethod' /> <span>Pickup</span></label></li>
                    </ul>
                </form>
                
            </div>
        </Wrapper>
    );
}

const PaymentMethod = ({ isValid, shouldOpen }) => {
    return (
        <Wrapper index={3} name='Payment Method' isValid={isValid} shouldOpen={shouldOpen}>
            <div className='mt-4'>
                <p className='text-bold'>How do you want to make payment?</p>
                <form>
                    <ul className='pay-methods pl-0'>
                        <li><label className='p-cursor'><input type='radio' name='payMethod' /> <span>Pay Online</span></label></li>
                        <li><label className='p-cursor'><input type='radio' name='payMethod' /> <span>Cash on Delivery</span></label></li>
                    </ul>
                </form>

            </div>
        </Wrapper>
    );
}

const Wrapper = ({ children, index, name, isValid, shouldOpen, className }) => {
    const [open, setOpen] = useState(false);

    const canOpen = shouldOpen || (open && isValid)
    return (
        <div className={`full-width text-left d-block bg-white p-3 ${className || ''}`}>
            <button className='unstyle-button d-flex align-center'>
                <FontAwesomeIcon className={`${isValid ? 'valid-check' : 'invalid-check'} mr-2`} icon={faCheckCircle} />
                <span className='mr-1'>{index}.</span>
                <span>{name}</span>
            </button>
            {
                canOpen &&
                children
            }
        </div>
    );
}