export const openCart = () => {
    return { type: OPEN_CART }
}

export const closeCart = () => {
    return { type: CLOSE_CART }
}

export const removeSearchArea = () => {
    return { type: REMOVE_SEARCH_AREA }
}

export const openSearchArea = () => {
    return { type: OPEN_SEARCH_AREA }
}

export const resetAppData = () => {
    return { type: RESET_APP_DATA }
}

//app data constants
export const OPEN_CART = 'OPEN_CART_AREA';
export const CLOSE_CART = 'CLOSE_CART_AREA';
export const RESET_APP_DATA = 'RESET_APP_DATA';
export const REMOVE_SEARCH_AREA = 'REMOVE_SEARCH_AREA';
export const OPEN_SEARCH_AREA = 'OPEN_SEARCH_AREA';