import React from "react";
import { Link, useLocation } from "react-router-dom";

import './app_link.css';

export const AppLink = ({ children, route, className }) => {
    const { pathname } = useLocation();
    return (
        <Link className={`text-dark text-uppercase text-mont ${className || ''} ${pathname === route ? 'active' : ''}`} to={route}>{children}</Link>
    );
};
