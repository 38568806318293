import React from "react";
import { ProductDisplay } from "../../components/product_box/product_box";
import { products } from "../../mock/products/product_mock";


export default function CategoryProducts() {
    //TODO: write logic to get products for this category
    return (
        <div className=' mt-6'>
            <h6>Products for this category</h6>
            <ProductDisplay products={products} />
        </div>
    );
}


