import React from "react";
import './nav_row.css';

export default function NavRow({ children, className }) {
    return (
        <div className={`d-flex keila-nav-row-item j-content ${className || ''} `}>
            {children}
        </div>
    );
}


NavRow.displayName = 'each item in a row';