export const products = [
    {
        id: 1,
        title: 'Keila foods',
        image: 'assets/images/indomie.png',
        hasDiscount: false,
        price: 500,
        description: 'The Onion flavor was created in a quest to give consumers variant in taste; an opportunity for consumers to open up their taste buds and minds to the endless possibilities (in terms of flavors) of Indomie.'
    },
    {
        id: 2,
        title: 'Keila foods',
        image: 'assets/images/indomie.png',
        hasDiscount: false,
        price: 500,
    },
    {
        id: 3,
        title: 'Keila foods',
        image: 'assets/images/indomie.png',
        hasDiscount: true,
        price: 500,
    },
    {
        id: 4,
        title: 'Keila foods',
        image: 'assets/images/indomie.png',
        hasDiscount: true,
        price: 500,
    },
    {
        id: 5,
        title: 'Keila foods',
        image: 'assets/images/indomie.png',
        hasDiscount: true,
        price: 500,
    },
    {
        id: 6,
        title: 'Keila foods',
        image: 'assets/images/indomie.png',
        hasDiscount: true,
        price: 500,
    },
]