export const searchData = [
    {
        id: 1,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    },
    {
        id: 2,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    },
    {
        id: 3,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    },
    {
        id: 4,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    },
    {
        id: 5,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    },
    {
        id: 6,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    },
    {
        id: 7,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    },
    {
        id: 8,
        title: "Frozen chicken",
        quantity: 3,
        price: 20,
        image: 'assets/images/indomie.png',
    }
]