import React from "react"
import { Link } from "react-router-dom"
import Input, { Button, LoginOptions } from "../../components/input/input"
import "./register.css"

const Register = () => {
    return (
        <div className="register">
            <h4 className="mt-5 mb-5 text-center">Sign up</h4>
            <form>
                <div className="register-flex">
                    <div className="half-input mr-1">
                        <Input placeholder="First name" />
                    </div>
                    <div className="half-input ml-1">
                        <Input placeholder="Last name" />
                    </div>
                </div>
                <div>
                    <Input placeholder="Email" />
                </div>
                <div className="register-flex">
                    <div className="quarter-input mr-1">
                        <Input disabled={true} value="+234" />
                    </div>
                    <div className="three-quarter-input ml-1">
                        <Input placeholder="Phone number" />
                    </div>
                </div>
                <div>
                    <Input placeholder="Password" />
                </div>
                <div>
                    <Input placeholder="Confirm password" />
                </div>
                <Button type='button' value="Create account" />
            </form>
            <div className="text-center">
                <p className="mt-5">By signing up, you agree to the <br />
                    <Link to="">Terms & Conditions</Link>
                 </p>
                <LoginOptions />
                <p>
                    Already a user? <Link to="/login">Sign in</Link>
                </p>
            </div>
        </div>
        )
}

export default Register