import React, { useState } from "react";
import Search from "../search/search";
import { AppLink } from "./app_link";
import { useDispatch } from 'react-redux'
import NavRow from "./nav_row";

import './top_nav_item.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { openCart } from "../cart/app_data_action";

export default function TopNavItem() {
    const [term, setTerm] = useState('');
    const dispatch = useDispatch();
    return (
        <NavRow className='mt-4 mb-4'>
            <AppLink route='/'><img src='assets/images/keila-logo.svg' className='logo-height' alt='brand logo identity' /></AppLink>
            <Search term={term} onChange={(value) => setTerm(value)} onSearch={() => { }} />
            <button onClick={() => dispatch(openCart())} className='unstyle-button'>
                <div className='d-flex p-relative'>
                    <FontAwesomeIcon className='cart-img alt-two-color' icon={faShoppingCart} />
                    <div className='d-flex flex-vertical j-content-center'>
                        <div className='cart-item-count alt-two-bg-color text-center'>0</div>
                        <span className='text-rob'>Cart</span>
                    </div>
                </div>
            </button>
            <AppLink route='/login'>
                <div className='d-flex j-content-center'>
                    <FontAwesomeIcon className='user-img alt-two-color' icon={faUserCircle} /> <span> Login</span>
                </div>
            </AppLink>
        </NavRow>
    );
}

TopNavItem.displayName = 'top layer for nav'
