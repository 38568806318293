import React from "react";
import { items } from "../../mock/carousel/carousel_mock";
import AppCarousel from "../carousel/app_carousel";
import './banner.css';

export default function Banner(props) {
    return (
        <div className='d-flex j-content banner-area mt-4'>
            <LeftBannerContent />
            <RightBannerContent />
        </div>
    );
}

const LeftBannerContent = () => {
    
    return (
        <div className='mr-2 left-area'>
            <AppCarousel items={items} />
        </div>
    );
}


const RightBannerContent = () => {
    return (
        <div className='right-area d-flex flex-vertical'>
            <div className='fixed-banner mb-2'></div>
            <div className='last-item-visited'>
            </div>
        </div>
    );
}