import React from "react";
import { categories } from "../../mock/categories/categories_mock";
import { AppLink } from "../navigation/app_link";
import TwoRowSection from "../two_row_section/two_row_section";

import './featured_category.css';

export default function FeaturedCategories() {

    return (
        <div className=' mt-6'>
            <h6>Featured Categories for today</h6>
            <DisplayCategories categories={categories} />

        </div>
    );
}


export const DisplayCategories = ({ categories }) => {
    const colors = [
        '#d3e2fe',
        '#FED4F8',
        '#FDE3B3',
        '#ABFDB8'
    ]
    return (
        <div className='d-grid-items'>
            {
                (categories && categories.length) &&
                categories.map((category, index) => (
                    <AppLink key={category.id} className='d-flex' route={`/categories/${category.id}`}>
                        <TwoRowSection
                            top={() => <img src={category.image} title={category.title} alt={category.title} className='category-image' />}
                            bottom={() => <span>{category.title}</span>}
                            color={colors[index % colors.length]} />
                    </AppLink>
                ))
            }
        </div>

    );
}