import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import Footer from './footer/footer';

import './layout.css';
import NavMenu from './navigation/nav_menu';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Cart from './cart/cart';
import { removeSearchArea } from './cart/app_data_action';

export const Layout = (props) => {
    const { cartIsOpen } = useSelector(state => state.appData)
    const dispatch = useDispatch();
    useEffect(() => {
        if (cartIsOpen) {
            document.body.style.overflow = 'hidden';
        }
        return () => document.body.style.overflow = null;
    }, [cartIsOpen])

    return (
        <div className='keila-body p-relative'>
            <NavMenu />
            {
                cartIsOpen &&
                <Cart />
            }
            <Container className='keila-content text-rob'>
                {props.children}
            </Container>
            <Footer />
        </div>
    );
}

Layout.displayName = Layout.name;