import React from "react";
import { ProductDisplay } from "../../components/product_box/product_box";
import { products } from "../../mock/products/product_mock";

export default function NewProducts() {
    //TODO: write logic to get new products
    return (
        <div className=' mt-6'>
            <h6>New in Stock</h6>
            <ProductDisplay products={products} />
        </div>
    );
}


