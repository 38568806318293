import React from 'react';
import { useHistory } from 'react-router-dom';

export default function CheckoutButton({className, children}) {
    const history = useHistory();
    return (
        <button type='button' className={className || ''} onClick={() => history.push('/checkout')}>
            {children}
        </button>
    );
}