import React from "react";
import { AppLink } from "./app_link";
import NavRow from "./nav_row";

export default function CategoryNavItem() {
    const categoryRoutes = [
        { name: 'Home', route: '/', id: 1 },
        { name: 'Category', route: '/categories', id: 2 },
        { name: 'Promotions', route: '/promotions', id: 3 },
        { name: 'New', route: '/new', id: 4 },
        { name: 'Featured', route: '/featured', id: 5 },
    ]
    return (
        <NavRow className='mt-4 mb-4'>
            {
                categoryRoutes.map((categoryRoute) => (
                    <AppLink key={categoryRoute.id} route={categoryRoute.route}>
                        <span>{categoryRoute.name}</span>
                    </AppLink>)
                )
                    
            }
        </NavRow>
    );
}

CategoryNavItem.displayName = 'second layer for nav'