import React from "react";
import { Container } from "reactstrap";
import CategoryNavItem from "./category_nav_item";
import './nav_menu.css';
import TopNavItem from "./top_nav_item";

export default function NavMenu() {
    
    return (
        <div className='d-flex keila-nav-menu box-shadow p-sticky'>
            <Container>
                <TopNavItem />
                <CategoryNavItem />
            </Container>
        </div>
            

    );
}


NavMenu.displayName = 'main nav menu';