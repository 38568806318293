import React from "react";

import './two_row_section.css';

export default function TwoRowSection({ className, topClassName, bottomClassName, top, bottom, color }) {
    return (
        <div className={`d-flex flex-vertical j-content-center text-center text-capitalize text-rob ${className || ''}`}>
            <div className={`category-image-container bg-white ${topClassName || 'p-4'}`}>
                {top()}
            </div>
            <div className={`category-title-area alt-one-color p-2 ${bottomClassName || ''}`} style={{ backgroundColor: (color || 'white') }}>
                {bottom()}
            </div>
        </div>
    );
}


//<div class="product-card">
//    <div class="product-image">
//        <img src="assets/img/coat-01.jpeg">
//    </div>
//        <div class="product-info">
//            <h5>Winter Jacket</h5>
//            <h6>$99.99</h6>
//        </div>
//    </div>