import React from "react"
import { Link } from "react-router-dom"
import Input, { Button, LoginOptions } from "../../components/input/input"
import "./login.css"

const Login = () => {
    return (
        <div className="login">
            <h4 className="mt-5 mb-5 text-center">Sign up</h4>
            <form method="post" action="">
                <Input placeholder="Email" />
                <Input placeholder="Password" />
                <div className="login-link-div">
                    <Link to="" className="login-a">Forgot password?</Link>
                </div>
                <Button value="Log in" type="button" />
            </form>
            <LoginOptions />
            <div style={{ textAlign: "center" }}>
                <p>Don't have an account? <Link to="/register" className="">Sign up</Link></p>
            </div>
        </div>
        )
}

export default Login;