import React from "react";
import CheckoutButton from "../../components/checkout_button/checkout_button";
import { ProductDisplay, ProductSelection } from "../../components/product_box/product_box";
import { products } from "../../mock/products/product_mock";
import './product_detail.css';

export default function ProductDetail() {
    const [item] = products;
    return (
        <div>
            <div className='d-flex mt-4'>
                <div className='mr-3'>
                    <img src={item.image} alt={item.title} className='img-fluid detail-image' />
                </div>
                <div>
                    <p>Category Information</p>
                    <h1>{item.title}</h1>
                    <hr />
                    <p>Price: ₦{item.price}</p>
                    <div className='d-flex'>
                        <ProductSelection
                            className='mr-2'
                            isInCart={true}
                            quantityInCart={1}
                            onIncreaseQuantity={() => { }}
                            onReduceQuantity={() => { }}
                            onChangeQuantity={() => { }}
                            onAddToCart={() => { }} />
                        <CheckoutButton>Check out</CheckoutButton>
                    </div>
                    <h2 className='mt-4'>Product Description</h2>
                    <p>{item.description}</p>
                </div>
            </div>
            <hr className='mt-4' />
            <div className='complementary-products mt-4'>
                <h5 className='text-center'>Complementary Products</h5>
                <ComplementaryProduct id={item.id} />
            </div>
        </div>
    );
}


const ComplementaryProduct = ({ id }) => {
    //TODO get complementary products for products with Id = id
    return (
        <ProductDisplay products={products} />
    );
}