import React from "react"
import { Link } from "react-router-dom"
import "./input.css";

const Input = ({ placeholder, className, disabled, onChange, value }) => {

    console.log(placeholder)
    return (
        <>
            <input value={value || ""} onChange={onChange} disabled={disabled || false} className={`input ${className || ''}`} type="text" placeholder={placeholder} />
        </>
        )
}

export const Button = ({ value, type, className }) => {
    return (
        <button className={`login-btn ${className || ''}`} type={type} >{value}</button>
        )
}

export const LoginOptions = () => {
    return (
            <div className="login-options">
                <Link to=""><img src="assets/images/google.svg" alt="google logo" /></Link>
                <Link to=""><img src="assets/images/fb.svg" alt="facebook logo" /></Link>
                <Link to=""><img src="assets/images/apple.svg" alt="apple logo" /></Link>
            </div>
        )
}

export default Input;