import React from "react";
import { DisplayCategories } from "../../components/featured_category/featured_category";
import { categories } from "../../mock/categories/categories_mock";

import './categories.css';

export default function Categories() {
    //TODO: write logic to get all categories
    return (
        <div className=' mt-6'>
            <h6>Available Categories</h6>
            <DisplayCategories categories={categories} />
        </div>
    );
}


