import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import React, { useState } from "react";
import './cart.css';
import { closeCart } from "./app_data_action";
import { CartQantityHandle } from "../product_box/product_box";
import { carts } from "../../mock/cart/cart_mock";
import CheckoutButton from "../checkout_button/checkout_button";

export default function Cart() {
    const [hideableArea, setHideableArea] = useState(true);
    const dispatch = useDispatch();
    const hideCart = () => {
        console.log('clicked');
        if (hideableArea) {
            dispatch(closeCart());
        }
    }
    return (
        <div onClick={hideCart} className='cart-overlay p-fixed'>
            <div onMouseOver={() => setHideableArea(false)} onMouseLeave={() => setHideableArea(true)} className='cart-inner p-3 p-absolute'>
                <div className='d-flex j-content-center mb-4'>
                    <div className='close-area'>
                        <button onClick={() => dispatch(closeCart())} className='unstyle-button button-rounded'>
                            <FontAwesomeIcon className='alt-two-color' icon={faChevronRight} />
                        </button>
                    </div>
                    <span className='cart-title text-center text-mont'>YOUR CART</span>
                </div>
                {carts.map(cart => (
                    <CartItem
                        key={cart.id}
                        item={cart}
                        quantityInCart={1}
                        onChangeQuantity={() => { }}
                        onIncreaseQuantity={() => { }}
                        onReduceQuantity={() => { }} />))
                }

                {
                    (!!carts && !!carts.length)
                    &&
                    <div>
                        <hr className='mt-4' />
                        <div className='d-flex mt-4 j-content'>
                            <span>Subtotal</span>
                            <span>₦1000</span>
                        </div>
                        <button className='clear-cart-items text-uppercase full-width mt-2'>clear items</button>
                        <CheckoutButton className='proceed-to-checkout text-uppercase full-width mt-2 mb-2'>proceed to checkout</CheckoutButton>
                    </div>
                }
            </div>
        </div>
    );
}

const CartItem = ({ item, quantityInCart, onIncreaseQuantity, onReduceQuantity, onChangeQuantity }) => {
    return (
        <div className='d-flex flex-vertical bg-white mb-3 p-3'>
            <div className='d-flex j-content'>
                <div className='cart-item-title'>
                    <span>{item.title}</span>
                </div>
                <button className='unstyle-button'>
                    <FontAwesomeIcon className='alt-two-color' icon={faTimes} />
                </button>
            </div>

            <div className='d-flex j-content-end cart-second-item'>
                <CartQantityHandle
                    quantityInCart={quantityInCart}
                    onChangeQuantity={onChangeQuantity}
                    onIncreaseQuantity={onIncreaseQuantity}
                    onReduceQuantity={onReduceQuantity} />
                <span>₦{item.price}</span>
                <img src={item.image} alt={item.title} className='fixed-product-height' />
            </div>
        </div>
    );
}