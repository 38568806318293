import React from "react";

import './footer.css';

export default function Footer() {
    return (
        <footer className="site-footer mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <h6>About</h6>
                        <p className="text-justify">
                            Keila foods bring the best sea food to your doorstep.
                        </p>
                    </div>

                    <div className="col-xs-6 col-md-3">
                        <h6>Categories</h6>
                        <ul className="footer-links">
                            <li><a href="http://scanfcode.com/category/c-language/">Sea foods</a></li>
                            <li><a href="http://scanfcode.com/category/front-end-development/">Poultry</a></li>
                            <li><a href="http://scanfcode.com/category/back-end-development/">Hide and Skin</a></li>
                        </ul>
                    </div>

                    <div className="col-xs-6 col-md-3">
                        <h6>Quick Links</h6>
                        <ul className="footer-links">
                            <li><a href="http://scanfcode.com/about/">About Us</a></li>
                            <li><a href="http://scanfcode.com/contact/">Contact Us</a></li>
                            <li><a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
                <hr/>
      </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                        <p className="copyright-text">Copyright &copy; All Rights Reserved</p>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="social-icons">
                                <li><a className="facebook" href="/"><i className="fa fa-facebook"></i></a></li>
                                <li><a className="twitter" href="/"><i className="fa fa-twitter"></i></a></li>
                                <li><a className="dribbble" href="/"><i className="fa fa-dribbble"></i></a></li>
                                <li><a className="linkedin" href="/"><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
        </footer>
    );
}

Footer.displayName = 'footer component';