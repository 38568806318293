export const categories = [
    {
        id: 1,
        title: 'Keila foods',
        image: 'assets/images/category.png'
    },
    {
        id: 2,
        title: 'Keila foods',
        image: 'assets/images/category.png'
    },
    {
        id: 3,
        title: 'Keila foods',
        image: 'assets/images/category.png'
    },
    {
        id: 4,
        title: 'Keila foods',
        image: 'assets/images/category.png'
    },
    {
        id: 5,
        title: 'Keila foods',
        image: 'assets/images/category.png'
    }
]